.Footer {
  padding: 4vw 13vw;
  background-color: black;
  height: 17vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    height: 22vw;
  }
  @media (min-width: 700px) and (max-width: 1300px) {
    height: 20vw;
  }
  .logo-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .fb {
      background-image: url("../../assets//img/LOGO_FB.png");
      background-size: 100% 100%;
      width: 3vw;
      height: 3vw;
      margin: .5vw .5vw;
      &:hover {
      background-image: url("../../assets/img/LOGO_FB_Hover.png");
      }
    }
    .ig {
      background-image: url("../../assets//img/LOGO_IG.png");
      background-size: 100% 100%;
      width: 3vw;
      height: 3vw;
      margin: .5vw .5vw;
      &:hover {
      background-image: url("../../assets/img/LOGO_IG_Hover.png");
      }
    }
    .yt {
      background-image: url("../../assets//img/LOGO_YT.png");
      background-size: 100% 100%;
      width: 3vw;
      height: 3vw;
      margin: .5vw .5vw;
      &:hover {
      background-image: url("../../assets/img/LOGO_YT_Hover.png");
      }
    }
    div {
      width: 3vw;
      height: 3vw;
      margin: .5vw .5vw;
    }
    #codm {
      width: 10vw;
      height: 3vw;
    }
  }
  .logo-2 {
    img {
      width: 8.5vw;
      height: 2.5vw;
      margin: 1vw 1vw;
    }
  }
  p {
    font-size: .7em;
    font-family: "Gunar";
    color: white;
    @media (max-width: 600px) {
      font-size: 0.3em;
    }
  }
}