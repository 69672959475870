.SectionTimeline {
  padding: 2vw 13vw;
  background-color: #141517;
  height: 60vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .timeline-img{
    width: 60vw;
    height: 3vw;
  }
}