.SectionRules {
  padding: 4vw 13vw;
  background-color: #601818;
  text-align: center;
  .rules-img {
    width: 45vw;
    height: 3vw;
    margin-bottom: 3vw;
  }
  .nav-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    border: none;
    .tabs-item {
      flex-basis: 49%;
      color: #fff;
      background-color: #201b1e;
      font-size: 1.2rem;
      font-family: "RefrigeratorDeluxeBold";
      padding: 1%;
      cursor: pointer;
      &:hover {
        color: #282626;
        background-color: #ffb42e;
      }
      @media screen and (max-width: 820px){
        font-size: .7rem;
        padding: 1.5%;
      }
      @media screen and (min-width: 820px) and (max-width: 980px) {
          font-size: .8rem;
          padding: 1%;
      }

      &.active {
        color: #282626;
        background-color: #ffb42e;
      }
    }
  }
  .separator {
    border-bottom: 1px solid #ffb42e;
    margin-bottom: 2%;
  }
  .tabs-controller {
    color: #fff;

    .rules-list {
      display: none;
      text-align: left;
      font-family: sans-serif;
      @media screen and (max-width: 600px) {
        font-size: .6rem;
      }
      @media screen and (max-width: 820px) and (orientation: landscape) {
        font-size: .8rem;
      }
      @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
          font-size: .8rem;
      }

      a {
        color: #ffb42e;
      }

      &.active {
        display: block;
      }

      .prize {
        font-size: 1.6rem;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: 820px) and (orientation: landscape) {
          font-size: 1.2rem;
        }
        @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
          font-size: 1.4rem;
        }

        .champ1 {
          font-size: 2.8rem;
          @media screen and (max-width: 600px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.6rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.8rem;
          }
        }

        .champ2 {
          font-size: 2.7rem;
          @media screen and (max-width: 600px) {
            font-size: .9rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.5rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.7rem;
          }
        }

        .champ3 {
          font-size: 2.6rem;
          @media screen and (max-width: 600px) {
            font-size: .8rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.4rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.6rem;
          }
        }

        .champ4 {
          font-size: 2.5rem;
          @media screen and (max-width: 600px) {
            font-size: .7rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.3rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.5rem;
          }
        }

        .champ5 {
          font-size: 2.4rem;
          @media screen and (max-width: 600px) {
            font-size: .6rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.2rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.4rem;
          }
        }

        .champ6 {
          font-size: 2.3rem;
          @media screen and (max-width: 600px) {
            font-size: .5rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1.1rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.3rem;
          }
        }

        .champ7 {
          font-size: 2.2rem;
          @media screen and (max-width: 600px) {
            font-size: .4rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: 1rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.2rem;
          }
        }

        .champ8 {
          font-size: 2rem;
          @media screen and (max-width: 600px) {
            font-size: 0.3rem;
          }
          @media screen and (max-width: 820px) and (orientation: landscape) {
            font-size: .9rem;
          }
          @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
            font-size: 1.1rem;
          }
        }

      }
    }
  }
}