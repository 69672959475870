.Navbar {
  width: 100%;
  height: 9vh;
  padding: 0 13vw;
  margin: 0;
  background-image: url("../assets/img/Header_Navigation.png.jpg");
  background-size: 97% 100%;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 0 15vw;
    height: 3vh;
  }

  @media (min-height: 1000px) and (max-height: 1300px) {
    height: 5vh;
  }
  // @media (min-width: 600px) and (max-width: 1300px) {
  //   height: 5vh;
  // }

  li {
    margin: 0 4.5vw;
    text-decoration: none;
    list-style: none;
    @media (max-width: 600px) {
      margin: 0 2.9vw;
    }
    @media (min-width: 600px) and (max-width: 1300px) {
      margin: 0 3vw;
    }
    p {
      color: white;
      font-family: "RefrigeratorDeluxeLight";
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.5rem;
      cursor: pointer;
      @media (max-width: 600px) {
        font-size: 0.6rem;
      }
      @media (min-width: 600px) and (max-width: 900px) {
        font-size: 1rem;
      }
      @media (min-width: 900px) and (max-width: 1200px) {
        font-size: 1.3rem;
      }
    }
  }
}

