@font-face {
  font-family: "RefrigeratorDeluxeLight";
  src: url("../../font/Refrigerator\ Deluxe\ Light.otf");
}

@font-face {
  font-family: "RefrigeratorDeluxeBold";
  src: url("../../font/RefrigeratorDeluxe-Bold.ttf");
}

@font-face {
  font-family: "Gunar";
  src: url("../../font/Gunar.ttf");
}