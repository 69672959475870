.SectionHeader {
  padding: 2vw 13vw;
  background-image: url("../../assets/img/BG_Section1.jpg");
  background-size: 100% 100%;
  height: 44.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "RefrigeratorDeluxeLight";
  font-weight: 400;
  font-style: normal;
  @media (max-width: 600px) and (orientation: portrait) {
    height: 40vw;
  }
  .header-pict {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vw;
    .logo {
      width: 20vw;
      height: 22vw;
      position: relative;
      left: 3vw;
      top: 3vw;
      @media (max-width: 600px) and (orientation: portrait) {
        margin-bottom: 4vw;
        top: 7vw;
        width: 20vw;
        height: 20vw;
      }
    }
    .scroll {
      float: right;
      width: 5vw;
      height: 8vw;
      position: relative;
      top: 3vw;
      left: 25vw;
      cursor: pointer;
    }
  }
  .header-info {
    width: 70%;
    text-align: center;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
    .text-headline {
      width: 40vw;
      height: 8vw;
    }
    p {
      color:#f6c855;
      text-transform: uppercase;
      font-size: 2rem;
      // width: 5vw;
      @media (max-width: 600px){
        font-size: 0.6rem;
      }
      @media (min-width: 600px) and (max-width: 900px) {
        font-size: 1rem;
      }
      @media (min-width: 900px) and (max-width: 1300px) {
        font-size: 1.5rem;
      }
    }
  }
  // .header-info {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100%;
  //   text-align: center;

  //   .header-pict {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-evenly;
  //     align-items: center;
  //     width: 100%;
  //     margin: 5vh 0;
  //     .logo {
  //       width: 25vw;
  //       height: 25vw;
  //     }
  
  //     .scroll {
  //       align-items: flex-end;
  //       width: 5vw;
  //       height: 8vw;
  //     }
  //   }

  //   .text-headline {
  //     width: 50vw;
  //     height: 10vw;
  //   }

  //   p {
  //     color: #f6c855;
  //     text-transform: uppercase;
  //     margin: 5px 0;
  //   }
  // }
}