.card {
  position: relative;
  margin: 0 1.5vw;
  .card-content {
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 3%;
    margin-bottom: 2vw;
    text-transform: uppercase;
    color: white;
    line-height: 2em;
    @media (max-width: 600px) {
      margin-bottom: 3vw;
      line-height: 0.5em;
    }
    @media (min-width: 700px) and (max-width: 1000px) {
      margin-bottom: 0;
      line-height: 1.5em;
    }
    @media (min-width: 700px) and (max-width: 1000px) and (orientation: landscape) {
      margin-bottom: 2vw;
      line-height: 1.5em;
    }
    .team-name {
      font-family: "RefrigeratorDeluxeLight";
      font-size: 2em;
      @media (max-width: 600px) {
        font-size: 0.5em;
      }
      @media (min-width: 700px) and (max-width: 1300px) and (orientation: portrait) {
        font-size: 1.5em;
      }
      @media (min-width: 700px) and (max-width: 1300px) and (orientation: landscape) {
        font-size: 1.2em;
      }
      // @media (min-width: 900px) and (max-width: 1300px) {
      //   font-size: 1em;
      // }
    }
    .team-status {
      font-family: "RefrigeratorDeluxeBold";
      font-size: 1.2em;
      @media (max-width: 600px) {
        font-size: 0.4em;
      }
      @media (min-width: 700px) and (max-width: 1300px) and (orientation: portrait) {
        font-size: 1.2em;
      }
      @media (min-width: 700px) and (max-width: 1300px) and (orientation: landscape) {
        font-size: .8em;
      }
    }
  }
}