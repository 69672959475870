.SectionGameModes {
  padding: 4vw 13vw;
  background-image: url("../../assets/img/BG_4.jpg");
  background-size: 100% 100%;
  height: 44.5vw;
  text-align: center;
  .game-modes-img {
    width: 60vw;
    height: 3vw;
  }
  .cards {
    display: flex;
    flex-direction: row;
    margin: 3vw 0;
    align-items: center;
    justify-content: center;
    .card-bm {
      background-image: url("../../assets/img/Image_BM.jpg");
      background-size: 100% 100%;
      position: relative;
      margin: 0 1.5vw;
      width: 25vw;
      height: 26vw;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        background-image: url("../../assets/img/Image_BM_Hover.jpg");
      }
    }
    .card-domi {
      background-image: url("../../assets/img/Image_DOMI.jpg");
      background-size: 100% 100%;
      position: relative;
      margin: 0 1.5vw;
      width: 25vw;
      height: 26vw;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        background-image: url("../../assets/img/Image_DOMI_Hover.jpg");
      }
    }
    .card-hp {
      background-image: url("../../assets/img/Image_HP.jpg");
      background-size: 100% 100%;
      position: relative;
      margin: 0 1.5vw;
      width: 25vw;
      height: 26vw;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        background-image: url("../../assets/img/Image_HP_Hover.jpg");
      }
    }
  }
}