.tab-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;

  .tab {
      background-color: #201b1e;
      color: white;
      width: calc(100%/4.3);
      padding: .5% 0;
      font-family: "RefrigeratorDeluxeBold";
      text-transform: uppercase;
      text-align: center;
      @media screen and (max-width: 600px){
        font-size: .5rem;
        padding: 1.2%;
      }
      @media screen and (min-width: 600px) and (max-width: 820px) {
        font-size: .6rem;
        padding: 1.5%;
      }
      @media screen and (min-width: 820px) and (max-width: 980px) {
        font-size: .7rem;
        padding: 1%;
      }

      &.active, &:hover {
          color: #282626;
          background-color: #ffb42e;
          cursor: pointer;
      }
  }
}

.tab-content {
  display: none;

  &.active {
    display: block;
  }
}
