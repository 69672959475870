@import "./common/font";

* {
  box-sizing: border-box;
}

html {
  body {
      background-color: #282626;
      width: 100%;
      margin: 0;
      line-height: initial;
      font-family: "GunarMedium";
      font-weight: initial;

    //   .content-container {
    //       @media screen and (orientation: portrait) {
    //           display: none;
    //       }
    //   }

      h1, h2, h3, h4, h5, h6, p {
          margin: 0;
          padding: 0;
      }

      a {
          text-decoration: none;

          &:hover {
              text-decoration: none;
          }
      }
  }
}



// @import 'reset';