.SectionInvitation {
  padding: 4vw 13vw;
  background-image: url("../../assets/img/BG_2.jpg");
  background-size: 100% 100%;
  height: 44.5vw;
  text-align: center;
  .invitational-team {
    width: 60vw;
    height: 3vw;
  }
  .cards {
    display: flex;
    flex-direction: row;
    margin: 3vw 0;
    align-items: center;
    justify-content: center;
  }
}